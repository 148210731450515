<template>
  <div class="recyclingInfo">
    <div class="title">
      <router-link :to="{name:'Home'}">首页</router-link>
      <a>&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;号商详情</a>
    </div>
    <a target="_blank" :href="info.link" class="list" v-html="info.cdata">
    </a>
  </div>
</template>
<script>
import {apiRecyclingInfo} from "@/request/API";

export default {
  name: 'recyclingInfo',
  props: {
  },
  components: {

  },
  data () {
    return {
      info:''
    }
  },
  methods: {
    getRecyclingInfo(){
      apiRecyclingInfo({
        id:this.$route.query.id
      }).then(res=>{
        this.info = res.data
      })
    }
  },
  mounted(){
    this.getRecyclingInfo()
  },
  watch: {
  },
  computed: {

  }
}
</script>

<style lang='less' scoped>
.recyclingInfo{
  width: 1200px;
  .title{
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    a:first-of-type{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }
    a:last-of-type{
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #FCAD13;
      cursor: pointer;
    }
    a{
      .Breadcrumb{
        font-size: 12px;
      }
    }
  }
}
</style>

<style  scoped>
.list >>> p{
  margin: 0;
}
.list >>> img{
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
